/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { keyframes, css } from "@emotion/core";

export type DonutChartProps = { percent: number; text: string };
export const DonutChart: React.FC<DonutChartProps> = ({ percent, text }) => {
  const donut = keyframes`
  0% {
        stroke-dasharray: 0, 100;
    }
    100% {
        stroke-dasharray: ${percent}, ${100 - percent};
    }
`;

  const donutfade = keyframes`
    0% {
        opacity: .2;
    }
    100% {
        opacity: 1;
    }
`;

  const colour = percent >= 90 ? "#4BB543" : "orange";

  return (
    <div
      css={css`
        animation: ${donutfade} 1s;
      `}
    >
      <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
        <circle cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
        <circle
          sx={{ stroke: "#EBEBEB" }}
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="3.5"
        ></circle>
        <circle
          css={css`
            animation: ${donut} 3s;
          `}
          sx={{
            transformOrigin: "center",
            stroke: colour,
          }}
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          strokeWidth="3.5"
          strokeDasharray={`${percent} ${100 - percent}`}
          strokeDashoffset="25"
        ></circle>
        <g sx={{ fill: colour }}>
          <text y="45%" transform="translate(0, 2)">
            <tspan
              sx={{
                fontSize: ".4em",
                lineHeight: 1,
                transform: "translateY(0.5em)",
                fontWeight: "bold",
              }}
              x="50%"
              textAnchor="middle"
            >
              {`${percent}%`}
            </tspan>
          </text>
          <text y="55%" transform="translate(0, 2)">
            <tspan
              css={css`
                animation: ${donutfadelong} 1s;
              `}
              sx={{
                fontSize: "0.2em",
                lineHeight: 1,
                transform: "translateY(0.5em)",
                textAlign: "center",
                textSnchor: "middle",
                color: "#666",
                fill: "#666",
              }}
              x="50%"
              textAnchor="middle"
            >
              {text}
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  );
};

export default DonutChart;

const donutfadelong = keyframes`
   0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
