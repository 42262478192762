/** @jsx jsx */
import { Box, Heading, jsx, Link, Styled, ThemeProvider } from "theme-ui";
import React from "react";
import { Markdown } from "../components/utils/markdown/markdown.interface";
import { ImageProps } from "../components/utils/image/Interfaces";
import { graphql } from "gatsby";
import Hero, { HeroProps } from "../components/hero/hero.component";
import Layout from "../components/layout/layout.component";
import { StyledMarkDown } from "../components/utils/markdown/markdown";
import Image from "../components/utils/image/image";
import SEO from "../components/utils/seo/seo";
import { getSeoMeta } from "../components/utils/seo/helper-functions";
import DonutChart from "../components/charts/donut-chart";

export type ProjectPageProps = {
  data: {
    contentfulProject: {
      image: ImageProps;
      name: string;
      technology: string[];
      services: string[];
      cms: string;
      design: string;
      description: Markdown;
      content: any;
      url: string;
      performance: number;
      accessibility: number;
      bestPractices: number;
      seo: number;
    };
  };
};
export const ProjectPage: React.FC<ProjectPageProps> = ({ data }) => {
  const { contentfulProject: project } = data;

  const techAndCms = [...project.technology, project.cms];

  //const tagline = techAndCms.map((t, i) => <span key={t}>{t} &bull; </span>);
  const heroProps: HeroProps = {
    leader: "Project:",
    headingLine1: "Project - ",
    headingLine2: project.name,
    tagLine: project.services.join(" \u2022 "),
  };

  return (
    <Layout>
      <SEO {...getSeoMeta(data.contentfulProject)}></SEO>
      <Hero {...heroProps}></Hero>
      <div
        sx={{
          variant: "layout.box",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image sx={{ width: "70%", mt: "-100px" }} {...project.image} />
      </div>
      <div sx={{ variant: "layout.narrow" }}>
        <StyledMarkDown data={project.content}></StyledMarkDown>
        <h3>Tech</h3>
        <div sx={{ pb: 2 }}>{techAndCms.join(" \u2022 ")}</div>
        <a
          sx={{ color: "primary" }}
          href={project.url}
          target="_blank"
          rel="noreferrer"
        >
          <span sx={{ textTransform: "uppercase" }}>visit site</span>
        </a>
        <Box mt={5}>
          <Heading as="h2">Lighthouse Scores</Heading>
          <div
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "95px",
            }}
          >
            <DonutChart percent={project.performance} text="Performance" />
            <DonutChart percent={project.accessibility} text="Accessibility" />
            <DonutChart percent={project.bestPractices} text="Best Practices" />
            <DonutChart percent={project.seo} text="SEO" />
          </div>
          <Box color="textMuted" sx={{ fontSize: [1] }}>
            <Box as="p">
              <Link
                color="primary"
                sx={{ fontSize: [1] }}
                href="https://developers.google.com/web/tools/lighthouse/"
              >
                Lighthouse
              </Link>{" "}
              is a free, open-source tool provided by Google, to analyse and
              suggest improvements to websites. When performing our benchmarks
              we emulate a mobile device on a "slow" mobile network.
            </Box>
            <Box as="p">
              While it would be nice to get 100 on everything, sometimes it's
              just not possible. Network latency, 3rd party components, complex
              pages, even corporate style guides can all affect the results.
              Irrespective of the numbers check out any site in our portfolio
              and I'm sure you'll be amazed at how quickly it loads.
            </Box>
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

export default ProjectPage;

export const pageQuery = graphql`
  query ProjectQuery($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      name
      technology
      url
      services
      slug
      performance
      accessibility
      bestPractices
      seo
      seoMetadata {
        title
        robotsNoIndex
        robotsNoFollow
        description
        facebookSeo {
          name
          type
          image {
            localFile {
              publicURL
            }
          }
          imageAlt
        }
        twitterSeo {
          title
          card
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      design
      cms
      client
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 2000)

        file {
          contentType
          url
        }
      }
    }
  }
`;
